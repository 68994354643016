import * as React from 'react';
import { Link } from 'gatsby';
//components
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { device } from '../components/GlobalStyles';
import { Seo } from '../components/Seo';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Technology } from '../components/Technology';
import { Testimonials } from '../components/Testimonials';

const Section = styled.section`
    margin-top: 8em;
    padding: 0em 1em;
    .locations {
        padding: 2em;
        .team-photo {
            display: none;
        }
    }
    .breadcrumbs {
        display: none;
    }
    h3 {
        margin-top: 1em;
        font-size: 24px;
    }
    iframe {
        width: 340px;
        height: 215px;
        margin: 0 auto;

        margin-top: 2em;
    }
    h4 {
        margin-bottom: 2em;
    }
    h1 {
        margin: 1em 0.2em 0.5em;
        font-size: 59px;
        line-height: 64px;
    }
    h2 {
        margin: 0.5em 0em;
    }
    p {
        font-size: 18px;
        margin-top: 1em;
    }
    p:nth-of-type(8) {
        margin-bottom: 4em;
    }
    h6 {
        font-size: 35px;
        margin-top: 1.5em;
    }
    @media ${device.tablet} {
        .locations {
            margin-top: 2em;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .team-photo {
                display: block;
                max-width: 540px;
            }
        }

        .videos {
            align-items: center;
            p {
                width: 900px;

                padding: 2em 1em;
            }
            display: flex;
        }
        iframe {
            width: 1060px;
            height: 315px;
            margin-top: 2em;
        }
        .breadcrumbs {
            font-size: 13px;
            display: flex;
            width: 210px;
            margin: 0 auto;
            justify-content: space-around;
            li {
                color: grey;
                cursor: pointer;
                &:hover {
                    color: black;
                }
            }
        }
        padding: 0em 1em;
        h2 {
            margin-bottom: 1em;
            text-align: center;
        }
    }
    @media ${device.laptop} {
        .locations {
            padding: 124px 0px;
        }
        iframe {
            width: 700px;
            height: 400px;
        }
        .team-photo {
            position: relative;
            bottom: 30px;
            min-width: 700px;
        }
        .content {
            max-width: 1440px;
            margin: 24px auto;
        }
    }
    @media ${device.desktop} {
        max-width: 1630px;
        margin: 0 auto;
        h1 {
            margin-top: 5em;
            margin-bottom: -1em;
            margin-left: 0.75em;
        }
        .videos {
            p {
                width: 50%;
                margin: 0em 1em;
            }
        }
    }
`; // eslint-disable-next-line @typescript-eslint/no-explicit-any
const AboutUs: Page<any> = () => {
    return (
        <>
            <Seo
                title="About Us - SmileTampa"
                description="about Smile Tampa dental office. A Prosthodontics & Implant Therapy Inc. "
            />
            <Section>
                <ul className="breadcrumbs">
                    <Link to="/">
                        <li style={{ listStyleType: 'none' }}>HOME</li>
                    </Link>
                    <li style={{ color: 'black' }}>ABOUT US</li>
                </ul>
                <h1>ABOUT US</h1>
                <div className="videos">
                    <p>
                        Dr. Reza Iranmanesh, Dr. Freshte Esfahanian, Dr. Nima Iranmanesh, Dr. Nika
                        Iranmanesh, and Dr. Armita Mashkouri of Tampa, Florida invite you to
                        experience the highest quality care and respect that you deserve. Since we
                        began our practice in the 1980s, our goal has been to stay on the front
                        lines of dentistry with revolutionary technology and techniques that enable
                        us to provide the finest long-lasting dentistry, solve the most complex
                        problems, and create an environment of optimal comfort.
                    </p>
                    <iframe
                        src="https://www.youtube.com/embed/tBM9ifuhWAo"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="videos">
                    <iframe
                        src="https://www.youtube.com/embed/5mKFpEP5eaM"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <p>
                        Our philosophy is simply to care for you as our patient with the highest
                        quality treatment and respect you deserve. From the first phone call you
                        make to our office, we begin creating a highly personalized experience for
                        you.
                    </p>
                </div>
                <div className="locations">
                    <div>
                        <h4>OUR LOCATIONS</h4>
                        <h3>Prosthodontics & Implant Therapy - TAMPA</h3>
                        <a href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1">
                            <p>2814 W Waters Ave, Tampa, FL 33614</p>
                        </a>
                        <a href="tel:813-933-6705">
                            <span>Phone: (813) 933-6705</span>
                        </a>
                        <br />
                        <span>Fax: (813) 933-8696</span>

                        <h3>Prosthodontics & Implant Therapy - SOUTH</h3>
                        <a href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US">
                            <p>11948 Boyette Road, Riverview, FL 33569</p>
                        </a>
                        <a href="tel:813-373-5525">
                            <span>Phone: (813) 373-5525</span>
                        </a>
                        <br />
                        <span>Fax: (813) 373-6895</span>

                        <h3>Prosthodontics & Implant Therapy - NORTH</h3>
                        <a href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US">
                            <p>20727 Sterlington Dr., Land O&apos; Lakes, FL 34638</p>
                        </a>
                        <span>Phone: (813) 575-9399</span>
                        <br />
                        <span>Fax: (813) 510-5540</span>
                    </div>
                    <div>
                        <StaticImage
                            className="team-photo"
                            alt="team photo"
                            src="../images/team.jpeg"
                        />
                    </div>
                </div>
            </Section>
            <Technology />
            <Testimonials />
        </>
    );
};
export default AboutUs;
AboutUs.Layout = DefaultLayout;
